import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/global/Layout"
import { PortableText } from "@portabletext/react"
import Form from "../components/templates/Form"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Seo from "../components/global/Seo"

export default function SinglePages({ data }) {
    const seo = data.sanityPages.seo
    return (
        <>
            <Layout>
                <Seo
                    title={seo?.title_tag}
                    description={seo?.meta_description}
                />
                <div className="section bg">
                    <div className="container">
                        <div className="md:flex flex-none gap-10">
                            <div className="md:w-2/3">
                                {data.sanityPages.title &&
                                    <h1 className="h1 mb-10">{data.sanityPages.title}</h1>
                                }
                                {(
                                    data.sanityPages.pageType === "plain" &&
                                    <div>
                                        <div>
                                            {data.sanityPages.image &&
                                                <GatsbyImage
                                                    image={data.sanityPages.image.asset.gatsbyImageData}
                                                    className="mb-10"
                                                />
                                            }
                                            <div className="md-content">
                                                {data.sanityPages._rawContent &&
                                                    <PortableText
                                                        value={data.sanityPages._rawContent}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                                    ||
                                    (
                                        data.sanityPages.pageType === "testimonial" &&
                                        data.sanityPages.testimonials.testimonials.map((node) => {
                                            return (
                                                <div className="border p-6 my-5">
                                                    <p>{node.text}</p>
                                                    <span className="font-medium italic">{node.name}</span>
                                                </div>
                                            )
                                        })
                                    )
                                    ||
                                    (
                                        data.sanityPages.pageType === "benefits" &&
                                        data.sanityPages.benefits.benefits.map((node) => {
                                            return (
                                                <div className="my-10">
                                                <h3 className="font-bold accent">{node.name}</h3>
                                                <p>{node.text}</p>
                                                </div>
                                            )
                                        })
                                    )
                                }
                            </div>
                            <div className="md:w-1/3">
                                <div className="border p-4">
                                    <h2 className="font-bold">Send a message</h2>
                                    <p className="text-sm">Have any questions or concerns? Send an email using the form below.</p>
                                    <Form
                                    source="https://www.cpabuysell.com"
                                    formName="Website Inquiry"
                                    subject="Website Contact Form"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export const query = graphql`
query ($id: String) {
    sanityPages(id: {eq: $id}) {
      title
      _rawContent
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      pageType
      testimonials {
        testimonials {
          text
          name
        }
      }
      benefits {
        benefits {
          text
          name
        }
      }
      seo {
        title_tag
        meta_description
      }
    }
  }
  
`